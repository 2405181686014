<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <div class="page-wrapper login">
      <div class="page-inner bg-brand-gradient">
        <div class="page-content-wrapper bg-transparent m-0">
          <div class="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
            <div class="d-flex align-items-center container p-0">
              <div class="page-logo width-mobile-auto m-0 align-items-center justify-content-center p-0 bg-transparent bg-img-none shadow-0 height-9">
                <a href="javascript:void(0)"
                   class="page-logo-link press-scale-down d-flex align-items-center">
                  <img :src="publicPath + 'media/birthday/logo_birthday_2023.png'" class="logo-edutalk" alt="Edutalk Tư Vấn"
                       aria-roledescription="logo">
                  <span class="page-logo-text mr-1"></span>
                </a>
              </div>
            </div>
          </div>
          <div class="flex-1" style="background: url(media/svg/pattern-1.svg) no-repeat center bottom fixed; background-size: cover;">
            <div class="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
              <div class="row">
                  <div class="col col-md-6 col-lg-6 col-xl-6 hidden-sm-down">
                      <h2 class="fs-xxl fw-500 mt-4 text-white">
                          CÔNG TY CỔ PHẦN <br>
                          TƯ VẤN ĐÁNH GIÁ VÀ PHÁT TRIỂN <br>
                          GIÁO DỤC EDUTALK
                          <br>
                          <br>
                          WEBSITE DÀNH CHO ĐỘI KINH DOANH, <br> NHÂN SỰ TẠI EDUTALK
                      </h2>
                      <div class="d-sm-flex flex-column align-items-center justify-content-center d-md-block">
                          <div class="px-0 py-1 mt-5 text-white fs-nano opacity-50">
                              Mạng xã hội
                          </div>
                          <div class="d-flex flex-row opacity-70">
                              <a href="https://www.facebook.com/edutalk.edu.vn/" target="_blank" class="mr-2 fs-xxl text-white">
                                  <i class="fab fa-facebook-square"></i>
                              </a>
                          </div>
                      </div>
                  </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 ml-auto">
                  <div class="card p-4 rounded-plus bg-faded">
                      <h2>Quên mật khẩu</h2>
                      <div v-if="messages.successRegister" class="alert alert-primary text-dark" role="alert">
                      <strong>Heads Up!</strong> Due to server maintenance from 9:30GTA to 12GTA, the verification emails could be delayed by up to 10 minutes.
                    </div>
                      <h5 class="mt-2 text-black-50">Nhập email/ số điện thoại bạn đã đăng ký.</h5>
                     <h5 class="mb-5 text-black-50">Chúng tôi sẽ gửi cho bạn một mã xác nhận để đặt lại mật khẩu.</h5>
                    <form id="js-login" class="form" @submit.prevent="handleSubmit(onSubmit)"  autocomplete="off">
                      <div class="form-group mb-5">
                        <ValidationProvider vid="email" name="Email" rules="required" v-slot="{ errors, classes }">
                          <input type="email" ref="email" name="email" id="emailForgot"
                                 :class="classes" class="form-control"
                                 placeholder="Nhập email của bạn"
                                 v-model=form.email>
                          <div class="fv-plugins-message-container">
                            <div data-field="name" data-validator="notEmpty"
                                 class="fv-help-block">{{ errors[0] }}
                            </div>
                          </div>
                        </ValidationProvider>

                      </div>
                      <div class="row no-gutters">
                          <button type="submit" id="js-login-btn" class="btn btn-block btn-primary btn- waves-effect waves-themed">
                            <i v-if="loading" class="el-icon-loading"></i>
                            Tiếp tục
                          </button>
                      </div>
                    <div class="col-lg-12 mt-3 text-center justify-content-edutalk ">
                        <router-link to="/login" v-slot="{ href, navigate }">
                            <a :href="href"  title="Quay trở lại trang đăng nhập"
                               @click="navigate"
                               data-filter-tags="Quay trở lại trang đăng nhập"
                               class=" waves-effect waves-themed">
                                Quay lại đăng nhập <i class="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
                            </a>
                        </router-link>
                    </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="position-absolute pos-bottom pos-left pos-right p-3 text-center text-white">
                2021 © Edutalk by&nbsp;<a href="https://edutalk.edu.vn"
                                          class="text-white opacity-40 fw-500" title="edutalk.edu.vn"
                                          target="_blank">edutalk.edu.vn</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
import {FORGOT_PASSWORD} from "../../../core/services/store/auth.module";

export default {
  name: "edutalk-forgot-password",
  data() {
    return {
        publicPath: process.env.VUE_APP_BASE_URL,
        messages: {
        successRegister: null
        },
        form: {
        email: "",
        },
        check_error: false,
        loading: false,
    };
  },
  computed: {
  },
  mounted() {

  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.$store
          .dispatch(FORGOT_PASSWORD, this.form)
          .then((res) => {
            if (!res.error){
                this.$message({
                  type: 'success',
                  message: res.message,
                  showClose: true
                });
            }
          })
          .catch((response) => {
            if (response.data?.data){
              this.$refs.form.setErrors(response.data.data);
            }
          }).finally(() => {
            this.loading = false;
          });
    },

  }
};
</script>
